import React, { useEffect, useState } from "react";
import {
    Container,
    Paper,
    Typography,
    TextField,
    Button,
    List,
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
    MenuItem,
    FormControl,
    InputLabel,
    Select,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Snackbar,
    Alert,
    DialogContentText
} from "@mui/material";

const CategoryManager = ({ api }) => {
    const [categories, setCategories] = useState([]);
    const [chapters, setChapters] = useState([]);
    const [newCategory, setNewCategory] = useState({ name: "", description: "", chapter_ids: [] });
    const [editCategory, setEditCategory] = useState(null);
    const [uncategorizedChapters, setUncategorizedChapters] = useState([]);
    const [open, setOpen] = useState(false);
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
    const [deleteConfirmation, setDeleteConfirmation] = useState({ open: false, categoryId: null });

    useEffect(() => {
        fetchCategories();
        fetchChapters();
        fetchUncategorizedChapters();
    }, []);

    const fetchCategories = async () => {
        try {
            const token = localStorage.getItem('access_token');
            const response = await fetch(`${api}/categories`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            const data = await response.json();
            setCategories(data.map(category => ({
                ...category,
                chapter_ids: category.chapters.map(chapter => chapter.id) || []
            })));
        } catch (error) {
            handleError('Failed to fetch categories');
        }
    };

    const fetchUncategorizedChapters = async () => {
        try {
            const token = localStorage.getItem('access_token');
            const response = await fetch(`${api}/chapters/uncategorized`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            const data = await response.json();
            console.log(data)
            setUncategorizedChapters(Array.isArray(data) ? data : []);
        } catch (error) {
            handleSnackbar('Failed to fetch uncategorized chapters', 'error');
        }
    };

    const fetchChapters = async () => {
        try {
            const token = localStorage.getItem('access_token');
            const response = await fetch(`${api}/chapters/all`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            const data = await response.json();
            setChapters(data);
        } catch (error) {
            handleError('Failed to fetch chapters');
        }
    };

    const handleCreateCategory = async () => {
        try {
            const token = localStorage.getItem('access_token');
            const response = await fetch(`${api}/category/create`, {
                method: "POST",
                headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${token}` },
                body: JSON.stringify({
                    name: newCategory.name,
                    description: newCategory.description,
                    chapter_ids: newCategory.chapter_ids
                }),
            });
            if (response.ok) {
                fetchCategories();
                setNewCategory({ name: "", description: "", chapter_ids: [] });
                handleSnackbar('Category created successfully', 'success');
            } else {
                handleError('Failed to create category');
            }
        } catch (error) {
            handleError('Failed to create category');
        }
    };

    const handleDeleteCategory = async (categoryId) => {
        try {
            const token = localStorage.getItem('access_token');
            const response = await fetch(`${api}/category/delete/${categoryId}`, {
                method: "DELETE",
                headers: { 'Authorization': `Bearer ${token}` }
            });
            if (response.ok) {
                fetchCategories();
                handleSnackbar('Category deleted successfully', 'success');
            } else {
                handleError('Failed to delete category');
            }
        } catch (error) {
            handleError('Failed to delete category');
        }
        // Close delete confirmation dialog
        setDeleteConfirmation({ open: false, categoryId: null });
    };

    const handleEditCategory = (category) => {
        setEditCategory({
            ...category,
            chapter_ids: category.chapters.map(chapter => chapter.id) || []
        });
        setOpen(true);
    };

    const handleUpdateCategory = async () => {
        try {
            const token = localStorage.getItem('access_token');
            const updatePayload = {
                ...(editCategory.name !== '' && { name: editCategory.name }),
                ...(editCategory.description !== '' && { description: editCategory.description }),
                ...(editCategory.chapter_ids && editCategory.chapter_ids.length > 0 && { chapter_ids: editCategory.chapter_ids })
            };

            console.log('Update Payload:', JSON.stringify(updatePayload));

            const response = await fetch(`${api}/category/update/${editCategory.id}`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(updatePayload),
            });
            if (response.ok) {
                fetchCategories();
                setOpen(false);
                handleSnackbar('Category updated successfully', 'success');
            } else {
                handleError('Failed to update category');
            }
        } catch (error) {
            handleError('Failed to update category');
        }
    };

    const handleSnackbar = (message, severity = 'success') => {
        setSnackbar({ open: true, message, severity });
    };

    const handleError = (message) => {
        setSnackbar({ open: true, message, severity: 'error' });
    };

    const handleCloseSnackbar = () => {
        setSnackbar({ ...snackbar, open: false });
    };

    const openDeleteConfirmation = (categoryId) => {
        setDeleteConfirmation({ open: true, categoryId });
    };

    return (
        <Container>
            {/* Snackbar for notifications */}
            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <Alert
                    onClose={handleCloseSnackbar}
                    severity={snackbar.severity}
                    sx={{ width: '100%' }}
                >
                    {snackbar.message}
                </Alert>
            </Snackbar>

            {/* Delete Confirmation Dialog */}
            <Dialog
                open={deleteConfirmation.open}
                onClose={() => setDeleteConfirmation({ open: false, categoryId: null })}
            >
                <DialogTitle>Confirm Delete</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete this category? This action cannot be undone.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDeleteConfirmation({ open: false, categoryId: null })}>
                        Cancel
                    </Button>
                    <Button
                        onClick={() => handleDeleteCategory(deleteConfirmation.categoryId)}
                        color="error"
                    >
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Create Category Form */}
            <Paper elevation={3} sx={{ p: 3, mb: 3, marginTop: 3 }}>
                <Typography variant="h5">Create Category</Typography>
                <TextField
                    label="Name"
                    fullWidth
                    value={newCategory.name}
                    onChange={(e) => setNewCategory({ ...newCategory, name: e.target.value })}
                    sx={{ my: 1 }}
                />
                <TextField
                    label="Description"
                    fullWidth
                    value={newCategory.description}
                    onChange={(e) => setNewCategory({ ...newCategory, description: e.target.value })}
                    sx={{ my: 1 }}
                />
                <FormControl fullWidth sx={{ my: 2 }}>
                    <InputLabel>Select Chapters</InputLabel>
                    <Select
                        multiple
                        value={newCategory.chapter_ids || []}
                        onChange={(e) => setNewCategory({ ...newCategory, chapter_ids: e.target.value })}
                    >
                        {chapters.map((chapter) => (
                            <MenuItem key={chapter.id} value={chapter.id}>
                                {chapter.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <Button variant="contained" sx={{ mt: 2 }} onClick={handleCreateCategory}>
                    Create
                </Button>
            </Paper>

            {/* Categories List */}
            <Paper elevation={3} sx={{ p: 3 }}>
                <Typography variant="h5">Categories</Typography>
                <List>
                    {categories.map((category) => (
                        <ListItem key={category.id} divider>
                            <ListItemText
                                primary={category.name}
                                secondary={category.description}
                                sx={{ paddingRight: 15 }}
                            />
                            <ListItemSecondaryAction>
                                <Button
                                    variant="outlined"
                                    sx={{ mr: 1 }}
                                    onClick={() => handleEditCategory(category)}
                                >
                                    Edit
                                </Button>
                                <Button
                                    variant="outlined"
                                    color="error"
                                    onClick={() => openDeleteConfirmation(category.id)}
                                >
                                    Delete
                                </Button>
                            </ListItemSecondaryAction>
                        </ListItem>
                    ))}
                </List>
            </Paper>

            <Paper elevation={3} sx={{ p: 3, mb: 3, marginTop: 3 }}>
                <Typography variant="h5">Uncategorized Chapters</Typography>
                {uncategorizedChapters.length === 0 ? (
                    <Typography>No uncategorized chapters available</Typography>
                ) : (
                    <List>
                        {uncategorizedChapters.map((chapter) => (
                            <ListItem key={chapter.id}>
                                <ListItemText primary={chapter.name} />
                            </ListItem>
                        ))}
                    </List>
                )}
            </Paper>

            {/* Edit Category Dialog */}
            {editCategory && (
                <Dialog open={open} onClose={() => setOpen(false)}>
                    <DialogTitle>Edit Category</DialogTitle>
                    <DialogContent>
                        <TextField
                            label="Name"
                            fullWidth
                            value={editCategory.name}
                            onChange={(e) => setEditCategory({ ...editCategory, name: e.target.value })}
                            sx={{ my: 1 }}
                        />
                        <TextField
                            label="Description"
                            fullWidth
                            value={editCategory.description}
                            onChange={(e) => setEditCategory({ ...editCategory, description: e.target.value })}
                            sx={{ my: 1 }}
                        />
                        <FormControl fullWidth sx={{ my: 2 }}>
                            <InputLabel>Select Chapters</InputLabel>
                            <Select
                                multiple
                                value={editCategory.chapter_ids || []}
                                onChange={(e) => setEditCategory({ ...editCategory, chapter_ids: e.target.value })}
                            >
                                {chapters.map((chapter) => (
                                    <MenuItem key={chapter.id} value={chapter.id}>
                                        {chapter.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setOpen(false)}>Cancel</Button>
                        <Button onClick={handleUpdateCategory} variant="contained">Update</Button>
                    </DialogActions>
                </Dialog>
            )}
        </Container>
    );
}

export default CategoryManager;