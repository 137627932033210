import React, { useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import {
  Container,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  CircularProgress,
  IconButton,
  Snackbar,
  Alert as MuiAlert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Select,
  MenuItem,
  Box,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import { useTheme } from '@mui/material/styles';
import { styled } from '@mui/system';
import { format } from 'date-fns';

const StyledAccordion = styled(Accordion)(({ theme }) => ({
  margin: '10px 0',
  boxShadow: 'none',
  border: '1px solid #ddd',
}));

const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#333' : '#f5f5f5',
  '& .MuiAccordionSummary-content': {
    alignItems: 'center',
  },
}));

const StyledAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#444' : '#fafafa',
  padding: '20px',
}));

const formatArticles = (text) => {
  if (!text) return "";
  text = text.replace(/(\w+)\s*[\n\r]+\s*(\w+)/g, '$1 $2');
  text = text.replace(/[\n\r]+/g, ' ').replace(/\s+/g, ' ').trim();
  text = text.replace(/(\s*)(?<!\()\b(\d+[\.\)°]|[a-zA-Z][\.\)])\s+/g, '<br/>$2 ');
  text = text.replace(
    /(\s*)\(([a-zA-Z])\)\s+/g,
    '<br/>($2) '
  );

  return text;
};


const HighlightedText = ({ text, highlight }) => {
  if (!text) return <span></span>;

  if (!highlight || !highlight.trim()) {

    return <span dangerouslySetInnerHTML={{ __html: text }} />;
  }

  try {

    const escapeRegExp = (string) => string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    const words = highlight.trim().split(/\s+/).map(escapeRegExp);
    const regex = new RegExp(`(${words.join('|')})`, 'gi');

    const highlightedHTML = text.replace(
      regex,
      (match) => `<span style="background-color: yellow; font-weight: bold;">${match}</span>`
    );

    return <span dangerouslySetInnerHTML={{ __html: highlightedHTML }} />;
  } catch (error) {
    console.error("Error in highlighting text:", error);
    return <span dangerouslySetInnerHTML={{ __html: text }} />;
  }
};

const ChaptersList = ({ api }) => {
  const context = useOutletContext() || {};
  const {
    searchQuery = '',
    selectedLanguage = 'en',
    selectedStatus = '',
    selectedYear = ''
  } = context;

  const [chapters, setChapters] = useState([]);
  const [favorites, setFavorites] = useState({});
  const [loading, setLoading] = useState(true);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogData, setDialogData] = useState({});
  const [references, setReferences] = useState({});
  const [subscriptionStatus, setsubscriptionStatus] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [chaptersPerPage, setChaptersPerPage] = useState(10);
  const [expandedChapters, setExpandedChapters] = useState({});
  const [expandedSections, setExpandedSections] = useState({});

  const theme = useTheme();

  useEffect(() => {
    const fetchChapters = async () => {
      try {
        const token = localStorage.getItem('access_token');
        const response = await fetch(`${api}/chapters/all`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });

        if (response.status === 403) {
          setSnackbarMessage('Your subscription has ended. Please renew your subscription to access the chapters.');
          setSnackbarSeverity('error');
          setSnackbarOpen(true);
          setChapters([]);
        }
        else if (response.status === 500) {
          setSnackbarMessage('Sorry, the server encountered an error.');
          setSnackbarSeverity('error');
          setSnackbarOpen(true);
          setChapters([]);
        }
        else {
          const data = await response.json();
          setsubscriptionStatus(true)
          setChapters(data);
        }
      } catch (error) {
        console.error('Error fetching chapters:', error);
        setChapters([]);
      } finally {
        setLoading(false);
      }
    };

    const fetchFavorites = async () => {
      try {
        const token = localStorage.getItem('access_token');
        if (!token) {
          setSnackbarMessage('You must be logged in to view favorites.');
          setSnackbarSeverity('warning');
          setSnackbarOpen(true);
          return;
        }
        const response = await fetch(`${api}/favorites`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });

        if (response.status === 403) {
          setSnackbarMessage('Your subscription has ended. Please renew your subscription to view your favorites.');
          setSnackbarSeverity('error');
          setSnackbarOpen(true);
          setFavorites({});
        } else {
          const data = await response.json();
          setFavorites(data.reduce((acc, fav) => {
            acc[`${fav.item_type}-${fav.item_id}`] = true;
            return acc;
          }, {}));
        }
      } catch (error) {
        console.error('Error fetching favorites:', error);
      }
    };

    const fetchReferences = async () => {
      try {
        const token = localStorage.getItem('access_token');
        const response = await fetch(`${api}/references`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        const data = await response.json();
        setReferences(data.reduce((acc, ref) => {
          acc[ref.id] = ref;
          return acc;
        }, {}));
      } catch (error) {
        console.error('Error fetching references:', error);
      }
    };

    fetchChapters();
    fetchFavorites();
    fetchReferences();
  }, [api]);


  useEffect(() => {
    if (searchQuery && searchQuery.trim() !== '') {

      const newExpandedChapters = {};
      const newExpandedSections = {};

      filteredChapters.forEach((chapter, chapterIndex) => {
        newExpandedChapters[chapterIndex] = true;

        chapter.sections_lst.forEach((section, sectionIndex) => {
          const uniqueKey = `${chapterIndex}-${sectionIndex}`;
          newExpandedSections[uniqueKey] = true;
        });
      });

      setExpandedChapters(newExpandedChapters);
      setExpandedSections(newExpandedSections);
    }
  }, [searchQuery]);

  const handleFavorite = async (itemId, itemType) => {
    const key = `${itemType}-${itemId}`;
    const isFavorited = favorites[key];
    const token = localStorage.getItem('access_token');

    if (!token) {
      setSnackbarMessage('You must be logged in to favorite items.');
      setSnackbarSeverity('warning');
      setSnackbarOpen(true);
      return;
    }

    if (isFavorited) {
      setDialogData({ itemId, itemType });
      setDialogOpen(true);
    } else {
      try {
        const response = await fetch(`${api}/favorites/add`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
          body: JSON.stringify({ item_id: itemId, item_type: itemType })
        });

        if (response.status === 401) {
          setSnackbarMessage('Unauthorized. Please log in again.');
          setSnackbarSeverity('error');
          setSnackbarOpen(true);
          return;
        }

        if (response.ok) {
          setFavorites(prev => ({ ...prev, [key]: !isFavorited }));
          setSnackbarMessage('Added to favorites!');
          setSnackbarSeverity('success');
        } else {
          setSnackbarMessage('Failed to update favorites.');
          setSnackbarSeverity('error');
        }
      } catch (error) {
        setSnackbarMessage('Error updating favorites.');
        setSnackbarSeverity('error');
      } finally {
        setSnackbarOpen(true);
      }
    }
  };

  const handleRemoveFavorite = async () => {
    const { itemId, itemType } = dialogData;
    const key = `${itemType}-${itemId}`;
    const token = localStorage.getItem('access_token');

    try {
      const response = await fetch(`${api}/favorites/remove`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({ item_id: itemId, item_type: itemType })
      });

      if (response.status === 401) {
        setSnackbarMessage('Unauthorized. Please log in again.');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
        return;
      }

      if (response.ok) {
        setFavorites(prev => {
          const updatedFavorites = { ...prev };
          delete updatedFavorites[key];
          return updatedFavorites;
        });
        setSnackbarMessage('Removed from favorites!');
        setSnackbarSeverity('success');
      } else {
        setSnackbarMessage('Failed to update favorites.');
        setSnackbarSeverity('error');
      }
    } catch (error) {
      setSnackbarMessage('Error updating favorites.');
      setSnackbarSeverity('error');
    } finally {
      setSnackbarOpen(true);
      setDialogOpen(false);
    }
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const naturalCompare = (a, b) => {
    if (!a) a = '';
    if (!b) b = '';

    const extractNumber = (text) => {
      const match = String(text).match(/(\d+)/);
      return match ? parseInt(match[0], 10) : 0;
    };

    const aNumber = extractNumber(a);
    const bNumber = extractNumber(b);

    if (aNumber === bNumber) {
      return String(a).localeCompare(String(b));
    }

    return aNumber - bNumber;
  };

  const handleChaptersPerPageChange = (e) => {
    setChaptersPerPage(Number(e.target.value));
    setCurrentPage(1);
  };

  const handleExpandChapter = (chapterIndex, isExpanded) => {
    setExpandedChapters(prev => ({
      ...prev,
      [chapterIndex]: isExpanded
    }));
  };

  const handleExpandSection = (chapterIndex, sectionIndex, isExpanded) => {
    const uniqueKey = `${chapterIndex}-${sectionIndex}`;
    setExpandedSections(prev => ({
      ...prev,
      [uniqueKey]: isExpanded
    }));
  };

  const contentMatchesSearch = (content, query) => {

    if (!query || query.trim() === '') return true;

    if (content === null || content === undefined) return false;

    try {

      const contentStr = String(content);
      const normalizedQuery = query.toLowerCase().trim();
      const searchTerms = normalizedQuery.split(/\s+/);

      const normalizedContent = contentStr.toLowerCase();
      return searchTerms.every(term => normalizedContent.includes(term));
    } catch (error) {
      console.error("Error in content matching:", error);
      return false;
    }
  };

  const filteredChapters = chapters.length === 0 ? [] : chapters
    .map(chapter => {
      try {

        const chapterTranslation = chapter.translations?.find(t => t.language_code === selectedLanguage);
        const chapterText = chapterTranslation?.text || chapter.name || '';
        const chapterMatchesQuery = contentMatchesSearch(chapterText, searchQuery);

        const filteredSections = (chapter.sections_lst || [])
          .map(section => {
            try {

              const sectionTranslation = section.translations?.find(t => t.language_code === selectedLanguage);
              const sectionText = sectionTranslation?.text || section.name || '';
              const sectionMatchesQuery = contentMatchesSearch(sectionText, searchQuery);

              const filteredArticles = (section.articles_lst || [])
                .filter(article => {
                  try {

                    const articleStatusMatches = !selectedStatus || article.status === selectedStatus;

                    const effectiveDate = article.effective_date ? new Date(article.effective_date) : null;
                    const articleYearMatches = !selectedYear || !effectiveDate ? true :
                      effectiveDate.getFullYear() === parseInt(selectedYear, 10);


                    const articleTranslation = article.translations?.find(t => t.language_code === selectedLanguage);
                    const articleTitle = articleTranslation?.text || article.title || '';
                    const articleDesc = articleTranslation?.desc || article.text || '';

                    const articleMatchesQuery = contentMatchesSearch(articleTitle, searchQuery) ||
                      contentMatchesSearch(articleDesc, searchQuery);

                    return articleStatusMatches && articleYearMatches &&
                      (articleMatchesQuery || sectionMatchesQuery || chapterMatchesQuery);
                  } catch (error) {
                    console.error("Error filtering article:", error);
                    return false;
                  }
                })
                .sort((a, b) => {
                  try {
                    const aTranslation = a.translations?.find(t => t.language_code === selectedLanguage);
                    const bTranslation = b.translations?.find(t => t.language_code === selectedLanguage);
                    const aText = aTranslation?.text || a.title || '';
                    const bText = bTranslation?.text || b.title || '';
                    return naturalCompare(aText, bText);
                  } catch (error) {
                    console.error("Error sorting articles:", error);
                    return 0;
                  }
                });

              // Include section if it or any of its articles match the search criteria
              if (filteredArticles.length > 0 || (sectionMatchesQuery && searchQuery && searchQuery.trim() !== '')) {
                return {
                  ...section,
                  articles_lst: filteredArticles,
                  matchesSearch: sectionMatchesQuery
                };
              }
              return null;
            } catch (error) {
              console.error("Error processing section:", error);
              return null;
            }
          })
          .filter(section => section !== null)
          .sort((a, b) => {
            try {
              const aTranslation = a.translations?.find(t => t.language_code === selectedLanguage);
              const bTranslation = b.translations?.find(t => t.language_code === selectedLanguage);
              const aText = aTranslation?.text || a.name || '';
              const bText = bTranslation?.text || b.name || '';
              return naturalCompare(aText, bText);
            } catch (error) {
              console.error("Error sorting sections:", error);
              return 0;
            }
          });

        // Include chapter if it or any of its sections match the search criteria
        if (filteredSections.length > 0 || (chapterMatchesQuery && searchQuery && searchQuery.trim() !== '')) {
          return {
            ...chapter,
            sections_lst: filteredSections,
            matchesSearch: chapterMatchesQuery
          };
        }
        return null;
      } catch (error) {
        console.error("Error processing chapter:", error);
        return null;
      }
    })
    .filter(chapter => chapter !== null)
    .sort((a, b) => {
      try {
        const aTranslation = a.translations?.find(t => t.language_code === selectedLanguage);
        const bTranslation = b.translations?.find(t => t.language_code === selectedLanguage);
        const aText = aTranslation?.text || a.name || '';
        const bText = bTranslation?.text || b.name || '';
        return naturalCompare(aText, bText);
      } catch (error) {
        console.error("Error sorting chapters:", error);
        return 0;
      }
    });

  const startIndex = (currentPage - 1) * chaptersPerPage;
  const endIndex = startIndex + chaptersPerPage;

  if (loading) {
    return (
      <Container
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh'
        }}
      >
        <CircularProgress />
      </Container>
    );
  }

  return (
    <Container>
      <Typography sx={{ textAlign: "center" }} variant="h6">Rwandan Labour Laws</Typography>

      {subscriptionStatus === false ? (
        <Typography variant="h6" align="center" color="error" sx={{ marginTop: 2 }}>
          Your subscription has ended. Please renew your subscription to access the chapters.
        </Typography>
      ) : (
        <>
          {searchQuery && searchQuery.trim() !== '' && (
            <Typography sx={{ my: 2, color: 'text.secondary', textAlign: 'center' }}>
              Search results for: <strong>"{searchQuery}"</strong> ({filteredChapters.length} chapter(s) with matching content)
            </Typography>
          )}

          {filteredChapters.slice(startIndex, endIndex).map((chapter, chapterIndex) => (
            <StyledAccordion
              key={chapterIndex}
              expanded={!!expandedChapters[chapterIndex]}
              onChange={(_, isExpanded) => handleExpandChapter(chapterIndex, isExpanded)}
              sx={chapter.matchesSearch && searchQuery ? { borderLeft: '4px solid green' } : {}}
            >
              <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h6">
                  📖 <HighlightedText
                    text={chapter.translations?.find(t => t.language_code === selectedLanguage)?.text || chapter.name || ''}
                    highlight={searchQuery || ''}
                  />
                  <IconButton onClick={(e) => { e.stopPropagation(); handleFavorite(chapter.id, 'chapter'); }}>
                    {favorites[`chapter-${chapter.id}`] ? <StarIcon /> : <StarBorderIcon />}
                  </IconButton>
                </Typography>
              </StyledAccordionSummary>
              <StyledAccordionDetails>
                {chapter.sections_lst.map((section, sectionIndex) => (
                  <StyledAccordion
                    key={sectionIndex}
                    expanded={!!expandedSections[`${chapterIndex}-${sectionIndex}`]}
                    onChange={(_, isExpanded) => handleExpandSection(chapterIndex, sectionIndex, isExpanded)}
                    sx={section.matchesSearch && searchQuery ? { borderLeft: '4px solid blue' } : {}}
                  >
                    <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography variant="h6">
                        <HighlightedText
                          text={section.translations?.find(t => t.language_code === selectedLanguage)?.text || section.name || ''}
                          highlight={searchQuery || ''}
                        />
                        <IconButton onClick={(e) => { e.stopPropagation(); handleFavorite(section.id, 'section'); }}>
                          {favorites[`section-${section.id}`] ? <StarIcon /> : <StarBorderIcon />}
                        </IconButton>
                      </Typography>
                    </StyledAccordionSummary>
                    <StyledAccordionDetails>
                      <Typography variant="subtitle2" gutterBottom>Articles</Typography>
                      {section.articles_lst.map((article, articleIndex) => {
                        try {
                          // Check if article text or description matches search for highlighting
                          const articleTranslation = article.translations?.find(t => t.language_code === selectedLanguage);
                          const articleTitle = articleTranslation?.text || article.title || '';
                          const articleDesc = articleTranslation?.desc || article.text || '';
                          const directMatch = searchQuery && searchQuery.trim() !== '' && (
                            contentMatchesSearch(articleTitle, searchQuery) ||
                            contentMatchesSearch(articleDesc, searchQuery)
                          );

                          return (
                            <StyledAccordion
                              key={articleIndex}
                              sx={directMatch ? { borderLeft: '4px solid orange' } : {}}
                            >
                              <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography variant="body1">
                                  <HighlightedText text={articleTitle} highlight={searchQuery || ''} />
                                  <IconButton onClick={(e) => { e.stopPropagation(); handleFavorite(article.id, 'article'); }}>
                                    {favorites[`article-${article.id}`] ? <StarIcon /> : <StarBorderIcon />}
                                  </IconButton>
                                </Typography>
                              </StyledAccordionSummary>
                              <StyledAccordionDetails>
                                <Typography>
                                  <HighlightedText text={formatArticles(articleDesc)} highlight={searchQuery || ''} />
                                </Typography>
                                {article.effective_date && (
                                  <Typography variant='h4' sx={{ mt: 2 }}>
                                    Effective Date: {format(new Date(article.effective_date), 'MMMM dd, yyyy')}
                                  </Typography>
                                )}
                                {article.status && (
                                  <Typography variant='h4'>Status: {article.status}</Typography>
                                )}
                                {article.reference_id && references[article.reference_id] && (
                                  <Typography variant='h4'>
                                    Reference: <a
                                      href={`${api}/reference/${article.reference_id}/download`}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      style={{ color: theme.palette.primary.main }}
                                    >
                                      {references[article.reference_id].description}
                                    </a>
                                  </Typography>
                                )}
                              </StyledAccordionDetails>
                            </StyledAccordion>
                          );
                        } catch (error) {
                          console.error("Error rendering article:", error);
                          return null;
                        }
                      })}
                    </StyledAccordionDetails>
                  </StyledAccordion>
                ))}
              </StyledAccordionDetails>
            </StyledAccordion>
          ))}
        </>
      )}

      {/* Pagination */}
      {filteredChapters.length > 0 && (
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 3 }}>
          {/* Pagination Selector */}
          <Select
            value={chaptersPerPage}
            onChange={handleChaptersPerPageChange}
            displayEmpty
            sx={{ marginBottom: 2 }}
          >
            <MenuItem value={10}>Show 10</MenuItem>
            <MenuItem value={30}>Show 30</MenuItem>
            <MenuItem value={50}>Show 50</MenuItem>
            <MenuItem value={100}>Show 100</MenuItem>
          </Select>

          {/* Page List */}
          <Box>
            {Array.from({ length: Math.ceil(filteredChapters.length / chaptersPerPage) }, (_, pageIndex) => (
              <Button
                key={pageIndex}
                onClick={() => setCurrentPage(pageIndex + 1)}
                variant={pageIndex + 1 === currentPage ? 'contained' : 'outlined'}
                sx={{ mx: 0.5 }}
              >
                {pageIndex + 1}
              </Button>
            ))}
          </Box>
        </Box>
      )}

      {/* No results message */}
      {filteredChapters.length === 0 && searchQuery && searchQuery.trim() !== '' && subscriptionStatus && (
        <Typography variant="body1" align="center" sx={{ my: 4 }}>
          No results found for "<strong>{searchQuery}</strong>". Please try a different search term.
        </Typography>
      )}

      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <MuiAlert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>

      <Dialog
        open={dialogOpen}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Remove Favorite"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to remove this item from your favorites?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleDialogClose}
            sx={{ color: theme.palette.primary.main }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleRemoveFavorite}
            autoFocus
            sx={{ color: theme.palette.primary.main }}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default ChaptersList;