import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Typography, Box } from '@mui/material';
import { styled } from '@mui/system';

const FancyTypography = styled(Typography)({
    fontFamily: "'Subtle Ligatures', serif",
    fontSize: 'clamp(1rem, 1.2vw, 1.3rem)',
    textAlign: 'center',
    padding: '10px',
    whiteSpace: 'normal',
    wordBreak: 'break-word',
    maxWidth: '100%',
});


const QuoteSlideshow = ({ api }) => {
    const [quotes, setQuotes] = useState([]);

    useEffect(() => {
        const fetchQuotes = async () => {
            try {
                const response = await fetch(`${api}/quotes`);
                const data = await response.json();
                setQuotes(data.filter(quote => quote.visible));
            } catch (error) {
                console.error('Error fetching quotes:', error);
            }
        };

        fetchQuotes();
    }, [api]);

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
        adaptiveHeight: true,
    };

    return (
        <Box sx={{ width: '100%' }}>
            <Slider {...settings}>
                {quotes.map((quote, index) => (
                    <Box key={index} sx={{ display: 'flex', justifyContent: 'center' }}>
                        <FancyTypography>
                            {quote.text}
                        </FancyTypography>
                    </Box>
                ))}
            </Slider>
        </Box>
    );
};

export default QuoteSlideshow;
