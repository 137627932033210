import React from 'react';
import { Card, CardContent, Typography, Grid, Box } from '@mui/material';

const CardNavigation = ({ onCardClick }) => {
    return (
        <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={4}>
                    <Card onClick={() => onCardClick('man-categories')}>
                        <CardContent>
                            <Typography variant="h5" component="div">
                                Manage
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                add,edit and delete categories
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Card onClick={() => onCardClick('add')}>
                        <CardContent>
                            <Typography variant="h5" component="div">
                                Add Chapter
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                Add a new chapter with sections and articles.
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Card onClick={() => onCardClick('update-delete')}>
                        <CardContent>
                            <Typography variant="h5" component="div">
                                Update/Delete Chapter
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                Update or delete existing chapters.
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Card onClick={() => onCardClick('add-file')}>
                        <CardContent>
                            <Typography variant="h5" component="div">
                                Add reference file
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                Upload pdf files
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Card onClick={() => onCardClick('update-file')}>
                        <CardContent>
                            <Typography variant="h5" component="div">
                                Update or delete a reference file
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                you already know what to do
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Card onClick={() => onCardClick('add-reference')}>
                        <CardContent>
                            <Typography variant="h5" component="div">
                                Add reference
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                Add a new reference
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Card onClick={() => onCardClick('update-reference')}>
                        <CardContent>
                            <Typography variant="h5" component="div">
                                Update reference
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                update an existing reference
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Card onClick={() => onCardClick('manage-quotes')}>
                        <CardContent>
                            <Typography variant="h5" component="div">
                                Manage Quotes
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                Add/edit or delete and choose which quotes are shown
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Card onClick={() => onCardClick('manage-tarrifs')}>
                        <CardContent>
                            <Typography variant="h5" component="div">
                                Manage Tarrifs
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                See previous tarrifs and set the current tarrif
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Card onClick={() => onCardClick('see-users')}>
                        <CardContent>
                            <Typography variant="h5" component="div">
                                See users
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                All users
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Box>
    );
};

export default CardNavigation;
